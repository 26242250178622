import Vue from 'vue';
import Vuex from 'vuex';
import Localbase from 'localbase';

const db = new Localbase('db');
db.config.debug = false;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appTitle: process.env.VUE_APP_TITLE,
    token: '',
    userid: '',
    username: '',
    userPermissions: [],
    section: '',
    applicationSearchParams: {
      applicationNumber: '',
      customer: '',
      date: null,
      salesPerson: '',
      sageRef: '',
      paymentStatus: '',
    },
    creditNoteSearchParams: {
      customer: '',
      jobNumber: '',
      sageRef: '',
    },
    customerSearchParams: {
      address: '',
      contactName: '',
      customerName: '',
      type_id: '',
    },
    dashboardSearchParams: {
      customer: '',
      date: '',
      jobNumber: '',
      jobSite: '',
      jobStatus: '',
      sageRef: '',
      salesPerson: '',
    },
    drsSearchParams: {
      jobNumber: '',
      customer: '',
      salesPerson: '',
      chargehand: '',
      date: [],
    },
    invoiceSearchParams: {
      invoiceNumber: '',
      customer: '',
      date: null,
      salesPerson: '',
      sageRef: '',
      paymentStatus: '',
      includeDraftInvoices: 0,
    },
    purchasingSearchParams: {
      cancelled: 2,
      complete: 2,
      date: [],
      open: 1,
      orderNo: '',
      supplier: '',
      description: '',
    },
    quotationSearchParams: {
      approval: 0,
      customer: '',
      date: [],
      jobNumber: '',
      jobSite: '',
      salesPerson: 0,
    },
    statementDate: null,
    statementSageRef: '',
  },
  mutations: {
    setApplicationSearchParams(state, params) {
      state.applicationSearchParams = params;
    },
    setCreditNoteSearchParams(state, params) {
      state.creditNoteSearchParams = params;
    },
    setCustomerSearchParams(state, params) {
      state.customerSearchParams = params;
    },
    setDashboardSearchParams(state, params) {
      state.dashboardSearchParams = params;
    },
    setDrsSearchParams(state, params) {
      state.drsSearchParams = params;
    },
    setInvoiceSearchParams(state, params) {
      state.invoiceSearchParams = params;
    },
    setPurchasingSearchParams(state, params) {
      state.purchasingSearchParams = params;
    },
    setQuotationSearchParams(state, params) {
      state.quotationSearchParams = params;
    },
    setSatementParams(state, params) {
      state.statementDate = params.statementDate;
      state.statementSageRef = params.statementSageRef;
    },
    setSection(state, section) {
      state.section = section;
    },
    setCredentials(state, credentials) {
      state.token = credentials.json.token;
      state.userid = credentials.user;
      state.username = credentials.name;
      if (credentials.permissions) {
        state.userPermissions = credentials.permissions;
      } else {
        state.userPermissions = [];
      }
    },
    unsetCredentials(state) {
      state.token = '';
      state.userid = '';
      state.username = '';
      state.userPermissions = '';
    },
    useCredentials(state, credentials) {
      state.token = credentials.token;
      state.userid = credentials.id;
      state.username = credentials.name;
      if (credentials.permissions) {
        state.userPermissions = credentials.permissions;
      } else {
        state.userPermissions = [];
      }
    },
  },
  actions: {
    setCredentials({ commit }, credentials) {
      commit('setCredentials', credentials);
      db.collection('users').doc({ id: credentials.user }).get().then((document) => {
        if (document) {
          db.collection('users').doc({ id: document.id }).set({
            id: credentials.user,
            name: credentials.name,
            token: credentials.json.token,
            permissions: credentials.permissions,
          });
        } else {
          db.collection('users').add({
            id: credentials.user,
            name: credentials.name,
            token: credentials.json.token,
            permissions: credentials.permissions,
          });
        }
      });
    },
    unsetCredentials({ commit }) {
      commit('unsetCredentials');
      db.collection('users').delete();
    },
  },
  getters: {},
});
