import Vue from 'vue';
import { format } from 'date-fns';
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import JsonCSV from 'vue-json-csv'
import App from './App.vue';
import store from './store';
import vuetify from './plugins/vuetify';
import router from './router';

Vue.config.productionTip = false;

Vue.use(vueNumeralFilterInstaller, { locale: 'en-gb' });

Vue.filter('prettyDate', (value) => {
  if (!value) return '';
  const prettyDate = format(new Date(value), 'EEEE do MMMM yyy');
  return prettyDate;
});

Vue.filter('prettyDateTime', (value) => {
  if (!value) return '';
  const prettyDate = format(new Date(value), 'EEEE do MMMM yyy HH:mm');
  return prettyDate;
});

Vue.filter('shortDate', (value) => {
  if (!value) return '';
  const prettyDate = format(new Date(value), 'EE do MMM yyy');
  return prettyDate;
});

Vue.filter('tinyDate', (value) => {
  if (!value) return '';
  const prettyDate = format(new Date(value), 'do MMM yyy');
  return prettyDate;
});

Vue.filter('priceInPounds', (value) => {
  if (!value) return '';
  const price = precisionRound((value / 100), 2);
  return price;
});

function precisionRound(number, precision) {
  var factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
}

Vue.component('downloadCsv', JsonCSV);

new Vue({
  store,
  vuetify,
  router,
  render: (h) => h(App),
}).$mount('#app');
