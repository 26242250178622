<template>
  <v-app id="inspire">
    <v-main v-if="!hide">
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-3">
              <v-toolbar color="orange lighten-2">
                <v-toolbar-title>Riggott's IMS - Login</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    prepend-icon="mdi-account"
                    name="email"
                    label="Email"
                    type="text"
                    v-model="email"
                    color="orange lighten-2"
                  ></v-text-field>
                  <v-text-field
                    id="password"
                    prepend-icon="mdi-lock"
                    name="password"
                    label="Password"
                    type="password"
                    v-model="password"
                    color="orange lighten-2"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="orange lighten-2"
                  @click="tryLogin">
                  Login
                </v-btn>
              </v-card-actions>
            </v-card>
            <v-row class="pt-5">
              <v-col>
                <router-link to="/request-password">
                  <v-btn text>Forgot password</v-btn>
                </router-link>
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>
      </v-container>
      <v-snackbar
        v-model="failed"
         :timeout="5000"
      >
        Sorry your email or password was not recognised
        <template v-slot:action="{ attrs }">
        <v-btn
          class="text-h5"
          color="grey darken-2"
          text
          v-bind="attrs"
          @click="failed = false"
        >
          Close
        </v-btn>
      </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import Localbase from 'localbase';
import axios from '../axios';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      failed: false,
      hide: true,
    };
  },
  methods: {
    checkForCredentials() {
      const db = new Localbase('db');
      db.config.debug = false;
      db.collection('users').get().then((users) => {
        if (users[0]) {
          this.$store.commit('useCredentials', users[0]);
          // test if these are still valid
          const testToken = users[0].token;
          axios.post(`/users/testCredentials.json?token=${testToken}`)
            .then((response) => {
              if (response.data === true) {
                this.$router.push('start');
              } else {
                this.$store.dispatch('unsetCredentials');
                this.hide = false;
              }
            })
            .catch((error) => {
              console.log(error);
              this.$store.dispatch('unsetCredentials');
              this.hide = false;
            });
        } else {
          this.hide = false;
        }
      });
    },
    tryLogin() {
      const postData = {};
      postData.email = this.email;
      postData.password = this.password;
      axios.post('/users/login.json', postData)
        .then((response) => {
          this.$store.dispatch('setCredentials', response.data);
          this.$router.push('start');
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
  mounted() {
    this.checkForCredentials();
  },
};
</script>
